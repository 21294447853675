<template>
  <!--begin::Quotation Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="6">
          <div class="text-h5 color-custom-blue">Company & Contact Person</div>
        </v-col>
        <v-col md="6"> </v-col>
      </v-row>
    </v-container>
    <ListingTable :columnCount="6" :rowData="dataRows2">
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Image</th>
            <th class="simple-table-th" width="300">Contact Name</th>
            <th class="simple-table-th" width="200">Contact Email</th>
            <th class="simple-table-th" width="200">Contact Mobile</th>
            <th class="simple-table-th" width="300">Tag</th>
            <th class="simple-table-th" width="200">Company</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows2.length > 0">
            <tr
              link
              :key="index"
              v-for="(row, index) in dataRows2"
              class="alternate-listing-row"
            >
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <!-- :src=" getProfileImage(row.profile_logo) ?
                  getProfileImage(row.profile_logo) : $defaultProfileImage " -->
                  <v-avatar size="50" color="white">
                    <v-img
                      class="supplier-image"
                      :lazy-src="$defaultProfileImage"
                      :src="row.profile_logo"
                    >
                    </v-img>
                  </v-avatar>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis" v-if="row.contact">
                    {{ row.contact }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div
                  class="projectcosting-listing-customer"
                  v-if="row.customer"
                >
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.customer.contact_person"
                  >
                    <b>Email: </b>
                    {{ row.customer.contact_person.primary_email }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div
                  class="projectcosting-listing-customer"
                  v-if="row.customer"
                >
                  <p
                    class="m-0 custom-nowrap-ellipsis"
                    v-if="row.customer.contact_person"
                  >
                    <b>Mobile Number: </b>
                    {{ row.customer.contact_person.primary_phone }}
                  </p>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <template v-if="row && row.category">
                    <v-chip :color="row?.category_color" small label outlined>{{
                      row.category
                    }}</v-chip>
                  </template>
                  <em v-else class="text-muted"> no tag</em>
                </div>
              </td>
              <td class="simple-table-td" width="100">
                <div class="quotation-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Company: </b> {{ row.customer.company_name }}
                  </p>
                </div>
              </td>
            </tr>
          </template>
          <!-- <tr v-else-if="!dataLoading">
            <td colspan="3">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no company at the moment.
              </p>
            </td>
          </tr> -->
        </tbody>
      </template>
    </ListingTable>
  </v-container>
  <!--end::Quotation Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
// import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
// import JwtService from "@/core/services/jwt.service";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "company-contact-person",
  data() {
    return {
      customer: 0,
      userDetails: {},
      dataRows2: [
        {
          id: 1,
          profile_logo: null,
          contact: "Contact A",
          category: "Option A",
          category_color: "red",
          customer: {
            display_name: "Abc pvt lte",
            company_name: "Abc pvt lte",
            contact_person: {
              primary_email: "john@gmail.com",
              primary_phone: "+65 1234 5678",
            },
          },
        },
        {
          id: 2,
          profile_logo: null,
          contact: "Contact B",
          category: "Option B",
          category_color: "blue",
          customer: {
            display_name: "Abc pvt lte",
            company_name: "Abc pvt lte",
            contact_person: {
              primary_email: "john@gmail.com",
              primary_phone: "+65 1234 5678",
            },
          },
        },
      ],
      dataLoading: true,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {},
  components: {
    ListingTable,
  },
  methods: {},
  //   mounted() {
  //     this.userDetails = JwtService.currentUser();
  //   },
};
</script>
