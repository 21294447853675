<template>
  <v-container fluid>
    <v-row>
      <v-col md="3">
        <v-card class="rounded-xl" outlined style="border-color: blue">
          <v-list-item class="py-4">
            <v-list-item-content>
              <div class="text-center">
                <v-avatar size="80" color="blue lighten-4"
                  ><v-icon color="blue darken-4" size="50"
                    >mdi-domain</v-icon
                  ></v-avatar
                >
              </div>
              <v-list-item-title
                class="text-h5 font-weight-600 mt-4 text-center"
              >
                10 Company
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col md="3">
        <v-card class="rounded-xl" outlined style="border-color: green">
          <v-list-item class="py-4">
            <v-list-item-content>
              <div class="text-center">
                <v-avatar size="80" color="green lighten-4"
                  ><v-icon color="green darken-4" size="50"
                    >mdi-currency-usd</v-icon
                  ></v-avatar
                >
              </div>
              <v-list-item-title
                class="text-h5 font-weight-600 mt-4 text-center"
              >
                10 Costing
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col md="3">
        <v-card class="rounded-xl" outlined style="border-color: red">
          <v-list-item class="py-4">
            <v-list-item-content>
              <div class="text-center">
                <v-avatar size="80" color="red lighten-4"
                  ><v-icon color="red darken-4" size="50"
                    >mdi-currency-usd</v-icon
                  ></v-avatar
                >
              </div>
              <v-list-item-title
                class="text-h5 font-weight-600 mt-4 text-center"
              >
                10 Quotation
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col md="3"></v-col>
      <v-col md="5" class="custom-border-right">
        <table class="width-100">
          <tr>
            <th colspan="2" class="font-size-18 py-2">Project Costing</th>
          </tr>
          <tr>
            <td class="font-size-18 font-weight-500 py-2" width="200">
              Opportunity Budget
            </td>
            <td class="font-weight-600 font-size-18 py-2">
              <span
                class="text--uppercase pr-2"
                v-if="opportunityArr && opportunityArr.project_budget"
                >{{ formatMoney(opportunityArr.project_budget) }}</span
              >
              <em v-else class="text--secondary"> no Project Budget</em>
            </td>
          </tr>
          <tr>
            <td class="font-size-18 font-weight-500 py-2" width="200">
              Company Purchase Order
            </td>
            <td class="font-weight-600 font-size-18 py-2">
              <span
                class="text--uppercase pr-2"
                v-if="opportunityArr.customer_purchase_order"
                >{{ opportunityArr.customer_purchase_order }}</span
              >
              <em v-else class="text--secondary"> no Company Purchase Order</em>
            </td>
          </tr>
        </table>
        <div class="mt-4 custom-border-top">
          <h3 class="py-2 font-size-18 text-capitalize">description</h3>
          <template v-if="opportunityArr.description">
            <read-more
              class="custom-read-more font-size-18 pb-1"
              more-str="read more"
              :text="opportunityArr.description"
              link="#"
              less-str="read less"
              :max-chars="200"
            >
            </read-more>
          </template>
          <template v-else
            ><em class="text--secondary"
              >No description for this opportunity</em
            ></template
          >
        </div>
        <div class="mt-5 custom-border-top" v-if="opportunityArr.members">
          <v-layout class="py-2">
            <v-flex>
              <h3 class="font-size-18 text-capitalize">members</h3>
            </v-flex>
            <v-flex
              class="text-right mr-4"
              v-if="
                getPermission('opportunity:update') && opportunityArr.status < 4
              "
            >
              <v-btn icon v-on:click="memberDialog = true">
                <v-icon color="cyan">mdi-cog</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-list-item
            v-for="(member, index) in opportunityArr.members"
            :key="index"
          >
            <v-list-item-avatar>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="$assetAPIURL(member.user.profile_logo)"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-size-18">{{
                member.user.full_name
              }}</v-list-item-title>
              <!--  <v-list-item-subtitle
                class="font-size-14 text-capitalize line-height-1-5"
                >total logged hours :
                {{ member.logged_hours }}</v-list-item-subtitle
              > -->
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                v-if="
                  getPermission('opportunity:delete') &&
                  opportunityArr.status < 4
                "
                v-on:click="deleteMember(member)"
              >
                <v-icon color="red lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>

        <div class="mt-5 custom-border-top" v-if="opportunityArr.managers">
          <v-layout class="py-2">
            <v-flex>
              <h3 class="font-size-18 text-capitalize">managers</h3>
            </v-flex>
            <v-flex
              class="text-right mr-4"
              v-if="
                getPermission('opportunity:update') && opportunityArr.status < 4
              "
            >
              <v-btn icon v-on:click="managerDialog = true">
                <v-icon color="cyan">mdi-cog</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-list-item
            v-for="(manager, index) in opportunityArr.managers"
            :key="index"
          >
            <v-list-item-avatar>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="$assetAPIURL(manager.user.profile_logo)"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-size-18">{{
                manager.user.full_name
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                v-if="
                  getPermission('opportunity:delete') &&
                  opportunityArr.status < 4
                "
                v-on:click="deleteManager(manager)"
              >
                <v-icon color="red lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-col>
      <v-col md="7">
        <perfect-scrollbar
          ref="sidebarScrollbar"
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh; position: relative"
          v-if="false"
        >
          <table class="width-100">
            <template>
              <tr>
                <th colspan="2" class="font-size-18 py-2">Project Costing</th>
              </tr>
              <!-- <tr>
                <td class="font-size-18 font-weight-500 py-2" width="200">
                  Project Value
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span
                    class="text--uppercase pr-2 font-weight-600"
                    v-if="opportunityArr && opportunityArr.project_value"
                    >{{ formatMoney(opportunityArr.project_value) }}</span
                  >
                  <em v-else class="text--secondary"> no Project Value</em>
                </td>
              </tr> -->

              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="200">
                  Opportunity Budget
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span
                    class="text--uppercase pr-2"
                    v-if="opportunityArr && opportunityArr.project_budget"
                    >{{ formatMoney(opportunityArr.project_budget) }}</span
                  >
                  <em v-else class="text--secondary"> no Project Budget</em>
                </td>
              </tr>
              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="200">
                  Company Purchase Order
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span
                    class="text--uppercase pr-2"
                    v-if="opportunityArr.customer_purchase_order"
                    >{{ opportunityArr.customer_purchase_order }}</span
                  >
                  <em v-else class="text--secondary">
                    no Company Purchase Order</em
                  >
                </td>
              </tr>
            </template>
          </table>
        </perfect-scrollbar>
      </v-col>
    </v-row>
    <template v-if="getPermission('opportunity:update')">
      <Dialog :commonDialog="memberDialog">
        <template v-slot:title>Members</template>
        <template v-slot:body>
          <v-container class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  :items="memberList"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model.trim="members"
                  label="Members"
                  solo
                  flat
                  item-color="cyan"
                  item-text="full_name"
                  item-value="id"
                  hide-details
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 3">
                      <span>{{ item.full_name }}</span>
                    </v-chip>
                    <span v-if="index === 3" class="grey--text font-size-12">
                      <template v-if="members && members.length"
                        >(+{{ members.length - 3 }} others)</template
                      >
                    </span>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Member Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-container class="py-0">
            <v-row>
              <v-col md="12" class="text-right">
                <v-btn
                  v-on:click="memberDialog = false"
                  :disabled="pageLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  v-on:click="updateMembers"
                  color="cyan"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </Dialog>
    </template>

    <template v-if="getPermission('opportunity:update')">
      <Dialog :commonDialog="managerDialog">
        <template v-slot:title>Managers</template>
        <template v-slot:body>
          <v-container class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  :items="managerList"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model.trim="managers"
                  label="Managers"
                  solo
                  flat
                  item-color="cyan"
                  item-text="full_name"
                  item-value="id"
                  hide-details
                  multiple
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 3">
                      <span>{{ item.full_name }}</span>
                    </v-chip>
                    <span v-if="index === 3" class="grey--text font-size-12">
                      <template v-if="managers && managers.length"
                        >(+{{ managers.length - 3 }} others)</template
                      >
                    </span>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Member Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-container class="py-0">
            <v-row>
              <v-col md="12" class="text-right">
                <v-btn
                  v-on:click="managerDialog = false"
                  :disabled="pageLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  v-on:click="updateManagers"
                  color="cyan"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </Dialog>
    </template>

    <template v-if="getPermission('opportunity:delete')">
      <MemberDelete :deleteDialog="deleteManagerDialog">
        <template v-slot:title>Delete Confirmation</template>
        <template v-slot:text>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Once you delete
            <span class="font-weight-700">{{ deleteDetail.full_name }}</span
            >, you won't be able to retrieve this later. Are you sure you want
            to delete
            <span class="font-weight-700">{{ deleteDetail.full_name }}</span>
            ?
          </h3>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="deleteLoading"
            :loading="deleteLoading"
            class="mx-2 custom-bold-button white--text"
            color="red lighten-1"
            v-on:click="deleteMangerConfirm"
          >
            Yes, Delete it
          </v-btn>

          <v-btn
            :disabled="deleteLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="deleteManagerDialog = false"
          >
            No, Keep it
          </v-btn>
        </template>
      </MemberDelete>
    </template>

    <template v-if="getPermission('opportunity:delete')">
      <MemberDelete :deleteDialog="deleteMemberDialog">
        <template v-slot:title>Delete Confirmation</template>
        <template v-slot:text>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Once you delete
            <span class="font-weight-700">{{ deleteDetail.full_name }}</span
            >, you won't be able to retrieve this later. Are you sure you want
            to delete
            <span class="font-weight-700">{{ deleteDetail.full_name }}</span>
            ?
          </h3>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="deleteLoading"
            :loading="deleteLoading"
            class="mx-2 custom-bold-button white--text"
            color="red lighten-1"
            v-on:click="deleteMemberConfirm"
          >
            Yes, Delete it
          </v-btn>

          <v-btn
            :disabled="deleteLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="deleteMemberDialog = false"
          >
            No, Keep it
          </v-btn>
        </template>
      </MemberDelete>
    </template>
  </v-container>
</template>

<script>
import { GET, DELETE, PATCH } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import MemberDelete from "@/view/pages/partials/Delete.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";

export default {
  name: "opportunity-detail",
  title: "Opportunity",
  mixins: [CommonMixin, ValidationMixin],
  props: {
    opportunityArr: {
      type: [Object],
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    "opportunityArr.opportunity"(members) {
      if (members && members.length > 0) {
        this.members = members.map((row) => {
          return row.user.id;
        });
      }
    },
    "opportunityArr.managers"(managers) {
      if (managers && managers.length > 0) {
        this.managers = managers.map((row) => {
          return row.user.id;
        });
      }
    },
  },
  data() {
    return {
      memberDialog: false,
      pageLoading: false,
      deleteDetail: new Object(),
      deleteMemberDialog: false,
      deleteManagerDialog: false,
      members: [],
      memberList: [],
      managers: [],
      managerList: [],
      managerDialog: false,
      loggedHours: "this_week",
      loggedHourLoading: false,
      loggedHourSeries: [{ data: [] }],
      loggedHourChartoptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "Last Week", value: "last_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last Month", value: "last_month" },
        { text: "This Year", value: "this_year" },
        { text: "Last Year", value: "last_year" },
      ],
    };
  },
  components: {
    Dialog,
    MemberDelete,
  },
  methods: {
    attchments() {
      let query = { ...this.$route.query };
      query.tab = "attachment";
      this.$router.push({ query });
    },
    deleteMember(member) {
      this.deleteDetail = member;
      this.deleteMemberDialog = true;
    },
    deleteMemberConfirm() {
      const _this = this;
      _this.deleteLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url:
            "opportunity/" +
            _this.opportunity +
            "/members/" +
            _this.deleteDetail.id,
        })
        .then(() => {
          _this.$emit("reload:opportunity", true);
          _this.deleteDetail = new Object();
          _this.deleteMemberDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.deleteLoading = false;
        });
    },
    deleteManager(member) {
      this.deleteDetail = member;
      this.deleteManagerDialog = true;
    },
    deleteMangerConfirm() {
      const _this = this;
      _this.deleteLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url:
            "opportunity/" +
            _this.opportunity +
            "/managers/" +
            _this.deleteDetail.id,
        })
        .then(() => {
          _this.$emit("reload:opportunity", true);
          _this.deleteDetail = new Object();
          _this.deleteManagerDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.deleteLoading = false;
        });
    },
    updateMembers() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "opportunity/" + _this.opportunity + "/members",
          data: { members: _this.members },
        })
        .then(() => {
          _this.$emit("reload:opportunity", true);
          _this.memberDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateManagers() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "opportunity/" + _this.opportunity + "/managers",
          data: { managers: _this.managers },
        })
        .then(() => {
          _this.$emit("reload:opportunity", true);
          _this.managerDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getMembers() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "opportunity/members",
        })
        .then(({ data }) => {
          _this.memberList = data;
          _this.managerList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getLoggedHours() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url:
            "opportunity/" + _this.opportunity + "/graph/" + _this.loggedHours,
        })
        .then(({ data }) => {
          _this.loggedHourChartoptions = {
            ..._this.loggedHourChartoptions,
            ...{
              xaxis: {
                categories: data.categories,
              },
            },
          };
          _this.loggedHourSeries = [
            { name: "Logged Hours", data: data.values },
          ];
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  mounted() {
    /* this.getMembers();
    this.getLoggedHours(); */
  },
};
</script>
